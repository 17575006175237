//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment'
import { TagSelect, StandardFormRow, Ellipsis, AvatarList } from '@/components'
const TagSelectOption = TagSelect.Option
const AvatarListItem = AvatarList.AvatarItem

export default {
  name: 'Project',
  components: {
    AvatarList,
    AvatarListItem,
    Ellipsis,
    TagSelect,
    TagSelectOption,
    StandardFormRow
  },
  data () {
    return {
      data: [],
      form: this.$form.createForm(this),
      loading: true
    }
  },
  filters: {
    fromNow (date) {
      return moment(date).fromNow()
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    handleChange (value) {
      console.log(`selected ${value}`)
    },
    getList () {
      this.data = [
        {
          id: '123',
          cover: 'https://gw.alipayobjects.com/zos/rmsportal/uMfMFlvUuceEyPpotzlq.png',
          content: '段落示意：螞蟻金服設計平臺 ant.design，用最小的工作量，無縫接入螞蟻金服生態，提供跨越設計與開發的體驗解決方案。',
          message: '消息',
          description: '小諾框架產品',
          href: 'https://xiaonuo.vip',
          title: '小諾',
          updatedAt: '2021-05-01 12:00:00',
          members: [
            {
              avatar: 'https://gw.alipayobjects.com/zos/rmsportal/ZiESqWwCXBRQoaPONSJe.png',
              name: '曲麗麗',
              id: 'member1'
            },
            {
              avatar: 'https://gw.alipayobjects.com/zos/rmsportal/tBOxZPlITHqwlGjsJWaF.png',
              name: '王昭君',
              id: 'member2'
            },
            {
              avatar: 'https://gw.alipayobjects.com/zos/rmsportal/sBxjgqiuHMGRkIjqlQCd.png',
              name: '董娜娜',
              id: 'member3'
            }
          ]
        },
        {
          id: '1234',
          cover: 'https://gw.alipayobjects.com/zos/rmsportal/iZBVOIhGJiAnhplqjvZW.png',
          content: '段落示意：螞蟻金服設計平臺 ant.design，用最小的工作量，無縫接入螞蟻金服生態，提供跨越設計與開發的體驗解決方案。',
          message: '消息',
          description: '小諾框架產品',
          href: 'https://xiaonuo.vip',
          title: '小諾',
          updatedAt: '2021-05-01 12:00:00',
          members: [
            {
              avatar: 'https://gw.alipayobjects.com/zos/rmsportal/ZiESqWwCXBRQoaPONSJe.png',
              name: '曲麗麗',
              id: 'member1'
            },
            {
              avatar: 'https://gw.alipayobjects.com/zos/rmsportal/tBOxZPlITHqwlGjsJWaF.png',
              name: '王昭君',
              id: 'member2'
            },
            {
              avatar: 'https://gw.alipayobjects.com/zos/rmsportal/sBxjgqiuHMGRkIjqlQCd.png',
              name: '董娜娜',
              id: 'member3'
            }
          ]
        },
        {
          id: '12345',
          cover: 'https://gw.alipayobjects.com/zos/rmsportal/iXjVmWVHbCJAyqvDxdtx.png',
          content: '段落示意：螞蟻金服設計平臺 ant.design，用最小的工作量，無縫接入螞蟻金服生態，提供跨越設計與開發的體驗解決方案。',
          message: '消息',
          description: '小諾框架產品',
          href: 'https://xiaonuo.vip',
          title: '小諾',
          updatedAt: '2021-05-01 12:00:00',
          members: [
            {
              avatar: 'https://gw.alipayobjects.com/zos/rmsportal/ZiESqWwCXBRQoaPONSJe.png',
              name: '曲麗麗',
              id: 'member1'
            },
            {
              avatar: 'https://gw.alipayobjects.com/zos/rmsportal/tBOxZPlITHqwlGjsJWaF.png',
              name: '王昭君',
              id: 'member2'
            },
            {
              avatar: 'https://gw.alipayobjects.com/zos/rmsportal/sBxjgqiuHMGRkIjqlQCd.png',
              name: '董娜娜',
              id: 'member3'
            }
          ]
        },
        {
          id: '1236',
          cover: 'https://gw.alipayobjects.com/zos/rmsportal/gLaIAoVWTtLbBWZNYEMg.png',
          content: '段落示意：螞蟻金服設計平臺 ant.design，用最小的工作量，無縫接入螞蟻金服生態，提供跨越設計與開發的體驗解決方案。',
          message: '消息',
          description: '小諾框架產品',
          href: 'https://xiaonuo.vip',
          title: '小諾',
          updatedAt: '2021-05-01 12:00:00',
          members: [
            {
              avatar: 'https://gw.alipayobjects.com/zos/rmsportal/ZiESqWwCXBRQoaPONSJe.png',
              name: '曲麗麗',
              id: 'member1'
            },
            {
              avatar: 'https://gw.alipayobjects.com/zos/rmsportal/tBOxZPlITHqwlGjsJWaF.png',
              name: '王昭君',
              id: 'member2'
            },
            {
              avatar: 'https://gw.alipayobjects.com/zos/rmsportal/sBxjgqiuHMGRkIjqlQCd.png',
              name: '董娜娜',
              id: 'member3'
            }
          ]
        }
      ]
      this.loading = false
    }
  }
}
