//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ArticleListContent } from '@/components'

export default {
  name: 'Article',
  components: {
    ArticleListContent
  },
  data () {
    return {
      loading: true,
      loadingMore: false,
      data: []
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    getList () {
      this.data = [
        {
          updatedAt: '2021-05-01 12:00:00',
          title: '小諾',
          owner: '俞寶山',
          description: 'snowy是小諾團隊產品',
          href: 'https://xiaonuo.vip',
          avatar: 'https://gw.alipayobjects.com/zos/rmsportal/ZiESqWwCXBRQoaPONSJe.png',
          name: '曲麗麗',
          id: 'member1'
        },
        {
          updatedAt: '2021-05-01 12:00:00',
          title: '小諾',
          owner: '徐玉祥',
          description: 'snowy是小諾團隊產品',
          href: 'https://xiaonuo.vip',
          avatar: 'https://gw.alipayobjects.com/zos/rmsportal/tBOxZPlITHqwlGjsJWaF.png',
          name: '王昭君',
          id: 'member2'
        },
        {
          updatedAt: '2021-05-01 12:00:00',
          title: '小諾',
          owner: '董夏雨',
          description: 'snowy是小諾團隊產品',
          href: 'https://xiaonuo.vip',
          avatar: 'https://gw.alipayobjects.com/zos/rmsportal/sBxjgqiuHMGRkIjqlQCd.png',
          name: '董娜娜',
          id: 'member3'
        }
      ]
      this.loading = false
    },
    loadMore () {
      this.loadingMore = false
    }
  }
}
